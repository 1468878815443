
  import { defineComponent, onMounted, computed, reactive, toRefs, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import MailingPreview from '@/components/mailing/MailingPreview.vue';
  import { useI18n } from 'vue-i18n';
  import useToast from '@/composables/use-toast';

  export default defineComponent({
    name: 'Mailing',
    components: { MailingPreview },
    setup() {
      const store = useStore();
      const { push } = useRouter();
      const { t, locale } = useI18n();
      const { successToast } = useToast();

      const state = reactive({
        email: '',
        clientCompany: '',
        clientName: '',
        language: 'en',
        salesPerson: 'Bartosz',
        type: 'general',
        personalizedSentence: '',
        subject: 'RemoteFun - Personalize your team building - invitation to cooperate with us'
      });

      const user = computed(() => store.state.user);
      const salesPersonJob = computed(() =>
        state.salesPerson === 'Bartosz' ? t('mailing.founder') : t('mailing.coFounder')
      );
      const salesPersonTitle = computed(() =>
        state.salesPerson === 'Bartosz' ? 'Founder' : 'Co-founder'
      );
      const salesPersonFull = computed(() =>
        state.salesPerson === 'Bartosz' ? 'Bartosz Bilejczyk' : 'Paulina Czajka'
      );

      const videoLink = computed(() =>
        state.language === 'pl'
          ? 'https://www.youtube.com/watch?v=01BMYa_1Yy8'
          : 'https://www.youtube.com/watch?v=zaA8U3DByYc'
      );

      const website = computed(() =>
        state.language === 'pl' ? 'https://getremotefun.com/pl' : 'https://getremotefun.com/'
      );

      const verb = computed(() => {
        if (state.language === 'pl') {
          return state.salesPerson === 'Bartosz' ? 'Chciałbym' : 'Chciałabym';
        } else {
          return '';
        }
      });

      const subjects = computed(() => {
        if (state.language === 'pl') {
          return [
            'RemoteFun - Wypróbuj wirtualnego team buildingu - zaproszenie do współpracy',
            'Wypróbuj RemoteFun - Praca zdalna + team building = sukces!',
            'Zabawa pomaga w pracy - Wypróbuj RemoteFun - Wirtualny team building'
          ];
        } else {
          return [
            'RemoteFun - Personalize your team building - invitation to cooperate with us',
            'Try RemoteFun - Remote work + virtual team building = success!',
            `Fun's part of the job. Try RemoteFun - Virtual team building`
          ];
        }
      });

      const sendEmail = async () => {
        const emails = state.email.split(',');
        const companies = state.clientCompany.split(',');

        for (const [i, email] of emails.entries()) {
          const content = `
            <p>${t('mailing.hi')} ${state.clientName},</p>
            <p>${t('mailing.myNameIs.0', {
              salesPerson: state.salesPerson,
              salesPersonJob: salesPersonJob.value
            })}${t('mailing.myNameIs.1')}
            </p>
            <p>${t('mailing.myNameIs.2')}${t('mailing.myNameIs.3')}${t('mailing.myNameIs.4', {
            clientCompany: companies[i]
          })}${t('mailing.myNameIs.5', {
            personalizedSentence: state.personalizedSentence
          })}
            </p>
            ${state.type === 'person' ? `<p>${t('mailing.areYouOpen')}</p>` : ''}
            ${
              state.type === 'person'
                ? `
              <a
                class="call"
                target="_blank"
                href="https://calendly.com/remotefun-virtual-teambuilding/remotefun-demo-call?month=2021-08"
              >
                ${t('mailing.scheduleMeeting')}
              </a>
            `
                : ''
            }

            ${
              state.type === 'general'
                ? `<p>${t('mailing.idLikeToSpeak', {
                    clientCompany: companies[i],
                    verb: verb.value
                  })}</p>`
                : ''
            }
            <p>${t('mailing.freePackage')}</p>
            <p>${t('mailing.answer')}</p>
            <p>${t('mailing.cheers')}<br />${state.salesPerson}</p>
            <div style="display: flex">
              <img style="width: 90px; height: 90px; border-radius: 50%; margin-right: 12px"
            src="
              ${
                state.salesPerson === 'Bartosz'
                  ? 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/f8d93d4f-7a8d-4331-b450-74d7531a38b0/392x392.jpg'
                  : 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/2f4622b4-2fe6-4959-ba0a-6cfc0827af08/400x400.jpg'
              }
            "
            alt="${salesPersonFull.value}">
              <span>
                <div class="name"><b>${salesPersonFull.value}</b></div>
                <div class="job"><b>${salesPersonTitle.value}</b></div>
                <a class="link" href="${videoLink.value}">${t('mailing.animation')}</a>
                <a class="link" href="${website.value}">${t('mailing.website')}</a>
                <div class="email">contact@getremotefun.com | +48 665 266 378</div>
                <a class="call" target="_blank" href="https://calendly.com/remotefun-virtual-teambuilding/remotefun-demo-call?month=2021-08">${t(
                  'mailing.scheduleCall'
                )}</a>
              </span>
            </div>
            <img style="width: 100%; height: auto; margin-top: 16px;"
          src="${
            state.language === 'pl'
              ? 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/924b456f-9883-4d67-a69f-8f7c2b167b67/2484x992.jpg'
              : 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/a80bc0aa-a862-4521-b6e6-1d23ff3c6f2a/2484x992.jpg'
          }"
          alt="RemoteFun Promo Banner">
            `;

          const emailData = {
            template_id: 'd-e485bc3da53c4bb4a38f43092a2a9716',
            from: {
              email: 'contact@getremotefun.com',
              name: `${state.salesPerson} ${t('mailing.from')}`
            },
            personalizations: [
              {
                to: [{ email }],
                dynamic_template_data: {
                  content,
                  subject: state.subject
                }
              }
            ]
          };

          await store.dispatch('sendEmail', emailData);
          successToast(`Email sent to ${email}`);
        }
      };

      onMounted(async () => {
        if (!['biley94@gmail.com', 'paulinaczajka94@gmail'].includes(user.value.email)) {
          push('/login');
          return;
        }
      });

      watch(
        () => state.language,
        newVal => {
          locale.value = newVal;
          state.subject = subjects.value[0];
        }
      );

      return {
        ...toRefs(state),
        videoLink,
        website,
        subjects,
        salesPersonJob,
        sendEmail,
        verb,
        salesPersonFull,
        salesPersonTitle
      };
    }
  });
