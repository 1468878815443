<template>
  <div class="p4 mailing-preview">
    <div>
      <h1>Preview</h1>
      <div>
        <b>From:</b>
        {{ salesPerson }}
        {{ $t('mailing.from') }}
      </div>
      <div>
        <b>To:</b>
        {{ email }}
      </div>
      <div>
        <b>Subject:</b>
        {{ subject }}
      </div>
    </div>
    <br />
    <p>{{ $t('mailing.hi') }} {{ clientName }},</p>
    <p>
      {{
        $t('mailing.myNameIs.0', {
          salesPerson: salesPerson,
          salesPersonJob: salesPersonJob
        })
      }}{{ $t('mailing.myNameIs.1') }}{{ $t('mailing.myNameIs.2') }}
    </p>
    <p>
      {{ $t('mailing.myNameIs.3') }}
      <b>
        {{
          $t('mailing.myNameIs.4', {
            clientCompany: clientCompany
          })
        }}
      </b>
      {{
        $t('mailing.myNameIs.5', {
          personalizedSentence: personalizedSentence
        })
      }}
    </p>
    <p v-if="type === 'person'">{{ $t('mailing.areYouOpen') }}</p>
    <a
      class="call"
      target="_blank"
      href="https://calendly.com/remotefun-virtual-teambuilding/remotefun-demo-call?month=2021-08"
      v-if="type === 'person'"
    >
      {{ $t('mailing.scheduleMeeting') }}
    </a>
    <p v-if="type === 'general'">
      {{ $t('mailing.idLikeToSpeak', { clientCompany, verb }) }}
    </p>
    <p>{{ $t('mailing.freePackage') }}</p>
    <!--    <p>{{ $t('mailing.animationWebsite') }}</p>-->
    <!--    <p>-->
    <!--      <a :href="videoLink">{{ $t('mailing.animation') }}</a>-->
    <!--      <a :href="website">{{ $t('mailing.website') }}</a>-->
    <!--    </p>-->
    <p>{{ $t('mailing.answer') }}</p>
    <p>
      {{ $t('mailing.cheers') }}
      <br />
      {{ salesPerson }}
    </p>
    <div style="display: flex">
      <img
        style="width: 100px; height: 100px; border-radius: 50%; margin-right: 12px"
        :src="
          salesPerson === 'Bartosz'
            ? 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/f8d93d4f-7a8d-4331-b450-74d7531a38b0/392x392.jpg'
            : 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/2f4622b4-2fe6-4959-ba0a-6cfc0827af08/400x400.jpg'
        "
        alt=""
      />
      <span>
        <div class="name">
          <b>{{ salesPersonFull }}</b>
        </div>
        <div class="job">
          <b>{{ salesPersonTitle }}</b>
        </div>
        <a target="_blank" class="link" :href="videoLink">{{ $t('mailing.animation') }}</a>
        <a target="_blank" class="link" :href="website">{{ $t('mailing.website') }}</a>
        <div class="email">contact@getremotefun.com | +48 665 266 378</div>
        <a
          class="call"
          target="_blank"
          href="https://calendly.com/remotefun-virtual-teambuilding/remotefun-demo-call?month=2021-08"
        >
          {{ $t('mailing.scheduleCall') }}
        </a>
      </span>
    </div>
    <img
      style="width: 100%; height: auto; margin-top: 16px"
      :src="
        language === 'pl'
          ? 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/924b456f-9883-4d67-a69f-8f7c2b167b67/2484x992.jpg'
          : 'http://cdn.mcauto-images-production.sendgrid.net/f41eeb8f26d7865c/a80bc0aa-a862-4521-b6e6-1d23ff3c6f2a/2484x992.jpg'
      "
      alt=""
    />

    <div class="mt3">
      <Button @click="$emit('send')">Send</Button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MailingPreview',
    props: {
      email: {
        type: String,
        default: ''
      },
      clientName: {
        type: String,
        default: ''
      },
      language: {
        type: String,
        default: ''
      },
      salesPerson: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      personalizedSentence: {
        type: String,
        default: ''
      },
      subject: {
        type: String,
        default: ''
      },
      videoLink: {
        type: String,
        default: ''
      },
      website: {
        type: String,
        default: ''
      },
      salesPersonJob: {
        type: String,
        default: ''
      },
      clientCompany: {
        type: String,
        default: ''
      },
      salesPersonFull: {
        type: String,
        default: ''
      },
      salesPersonTitle: {
        type: String,
        default: ''
      },
      verb: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped lang="scss">
  .mailing-preview {
    div,
    p {
      @include font(12px);
      margin: 16px 0;
    }

    a {
      margin-right: 12px;
      color: blue;
    }

    .name {
      @include font(15px);
      margin: 6px 0;
    }

    .job {
      @include font(11px);
      margin: 6px 0;
    }

    .email {
      @include font(12px);
      color: #222222;
      margin: 8px 0;
    }

    .call {
      @include font(12px);
      margin: 8px 0;
    }

    .link {
      @include font(12px);
      margin: 8px 12px 8px 0;
    }
  }
</style>
